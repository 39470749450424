import React from 'react';
import VirusSVG from '../../../images/svg/virus.svg';
import { Seo } from '../../Seo';
import * as styles from '../services.module.css';

export const DesinfectionContent = ({ handleOpenModal }) => {
  return (
    <div className={styles.initialSectionOfContent}>
      <Seo postTitle="Дезинфекция - вашего помещения" />
      <div className={styles.wrapOfFixedSection}>
        <div className={styles.headeOfTabContent}>
          <div className={styles.titleAndDescription}>
            <h1 className={styles.contentTitle}>Дезинфекция</h1>
            <p className={styles.contentDescription}>уничтожение бактерий, грибков, вирусов, плесени</p>
          </div>
          <VirusSVG className={styles.contentIcon} />
        </div>
        <ul className={styles.contentList}>
          <li className={styles.contentListItem}>Обработка поверхностей дезинфицирующим раствором</li>
          <li className={styles.contentListItem}>Использование ультрафиолетового бактерицидного излучения для обеззараживание воздуха </li>
          <li className={styles.contentListItem}>Предоставление полного пакета документов, необходимого для Роспотребнадзора.</li>
          <li className={styles.contentListItem}>Круглосуточный график - заявки принимаются 24/7, включая выходные и праздничные дни</li>
        </ul>
        <button onClick={handleOpenModal} className={styles.orderService}>заказать услугу</button>
      </div>

      <div className={styles.bodyContent}>
        <h2>Услуги дезинфекции</h2>
        <p>Под термином «дезинфекция помещений» подразумевается комплекс различных мероприятий, которые направлены на устранение микроорганизмов, грибков, бактерий и вирусов, являющихся опасными для здоровья людей. Дезинфекция может быть осуществлена в воздушной среде, и на различных поверхностях, что препятствует распространению опасных заболеваний. Важной особенностью дезинфекции также является и то, что с ее помощью удается справиться не только с первопричиной - например, микроорганизмами, но и с продуктами их жизнедеятельности – токсинами, несущими не меньшую опасность.</p>
        <h4>Характерные особенности и преимущества компании</h4>
        <p>Частная СанЭпидемСтанция в Москве «Continental Ecoservice» предлагает широкий спектр профессиональных санитарных услуг по обработке жилых и нежилых помещений различного назначения. Главным приоритетом в своей деятельности мы считаем донесение до наших заказчиков понимания важности дезинфекционных мероприятий, с целью сохранения жизни и здоровья людей.</p>
        <p>Служба дезинфекции «Continental Ecoservice» выполняет все виды работ по дезинфекции помещений и отдельных поверхностей.</p>
        <p>В своей работе наши специалисты используют профессиональные сертифицированные средства, инструментарий и инвентарь.</p>
        <h4>Виды дезинфекции</h4>
        <p>На сегодняшний день существует три типа дезинфицирующих мероприятий: профилактическая, текущая и заключительная.</p>
        <p>Профилактическую дезинфекцию проводят планово и с определенной регулярностью. Цель проведения данной процедуры - ликвидация возбудителей и их токсинов, вне зависимо от проявлений эпидемиологического процесса, для его предупреждения либо облегчения. К мероприятиям, проводимым в рамках плановой дезинфекции относятся: мытье рук, предметов, поверхностей и помещений с использованием специальных дезинфицирующих растворов.</p>
        <p>Показанием к проведению текущей дезинфекции является наличие источника возбудителя, как правило при вспышке заболевания. Ее проводят в лечебно-профилактических учреждениях, больницах, изоляторах и медицинских пунктах для предотвращения распространения инфекции за пределы организации. Приоритет направлен на биологические отправления заболевших, в которых сосредоточено наибольшее число возбудителей.</p>
        <p>Заключительная. Этот вид дезинфекции проводят после того, как пациент был выписан, либо после его смерти. Проводимые меры направлены на уничтожение возбудителей, потенциально способных остаться на изделиях и поверхностях, которые окружали больного во время болезни.</p>
        <h4>Услуги дезинфекции</h4>
        <p>Городской центр дезинфекции в Москве «Continental Ecoservice» предоставляет полный спектр высококачественных услуг по профилактической, текущей и заключительной дезинфекции жилых и нежилых помещений, авто- и авиатранспорта, предприятий пищевой промышленности, складских и подсобных помещений фабрик, заводов.</p>
        <h2>Виды предоставляемых услуг:</h2>
        <h4>Дезинфекция офисов</h4>
        <p>В идеале обеззараживание офисных помещений следует проводить в плановом режиме, с периодичностью раз в полгода.</p>
        <p>В некоторых же случаях меры необходимо предпринять немедленно:</p>
        <ul>
          <li>При эпидемии гриппа и прочих вирусных инфекций. Особенно если род деятельности фирмы предполагает частый прием посетителей.</li>
          <li>Вирусное или бактериальное заболевание одного или более сотрудников. Экстренная дезинфекция поможет предотвратить массовое заражение остального коллектива.</li>
          <li>В офисе появились насекомые, грызуны или другие вредители. В таком случае действия должны выполняться в определенной последовательности: например, обработка от клопов, тараканов, грызунов и т.д., после чего рекомендуется провести и дезинфекционные мероприятия.</li>
          <li>Появление плесени. Образование очагов грибка и плесневых пятен – повод к немедленному обращению в центр дезинфекции (Москва).</li>
        </ul>
        <p>Если оставить ситуацию без внимания, это приведет к ряду очень неприятных последствий, среди которых:</p>
        <ul>
          <li>нанесение ущерба отделке помещения;</li>
          <li>появление специфического запаха;</li>
          <li>вероятность порчи документации;</li>
          <li>заболевания верхних дыхательных путей у сотрудников и появление аллергических реакций;</li>
          <li>ухудшение имиджа компании.</li>
        </ul>
        <p>Городская служба дезинфекции в Москве «Continental Ecoservice» предоставит полный комплекс профессиональных услуг по дезинфекции офисов, что поможет избежать всех перечисленных неприятностей.</p>
        <p>Работы выполняются высококвалифицированными специалистами с использованием самых современных, эффективных и безопасных препаратов российского и зарубежного производства, а также профессиональных генераторов холодного и горячего тумана.</p>
        <h4>Дезинфекция домов</h4>
        <p>Профессиональная дезинфекция дома, направленная на глубокое обеззараживание целесообразна в таких ситуациях:</p>
        <ul>
          <li>переезд на новое место жительства, где до этого жили другие люди, возможно имеющие какие-либо заболевания;</li>
          <li>заселение в дом, где  давно никто не жил;</li>
          <li>после сдачи недвижимости в аренду;</li>
          <li>при проживании в доме человека с заразным инфекционным заболеванием, после его госпитализации либо смерти;</li>
          <li>если в доме проживают и разводятся домашние животные;</li>
          <li>в период эпидемии карантинных инфекций;</li>
          <li>в случае появления в доме грызунов, насекомых;</li>
          <li>при распространении на стенах и других поверхностях жилья грибков плесени.</li>
        </ul>
        <p>Проведение такой дезинфекции гарантирует уничтожение опасных бактерий, вирусов и аллергенов, а также общее оздоровление микроклимата, его пригодность для комфортного проживания.</p>
        <h4>Дезинфекция автотранспорта</h4>
        <p>Дезинфекция любого вида автотранспорта считается обязательной процедурой, обязанной проводится регулярно, с целью обеспечения надежной защиты перевозимых продуктов, водителя и пассажиров от различных инфекционных заболеваний и опасных микроорганизмов.</p>
        <h4>Дезинфекция авиасудов</h4>
        <p>Городская служба дезинфекции в Москве предлагает полный комплекс услуг по дезинфекции воздушных судов. Все мероприятия проводятся согласно строгому соблюдению европейских стандартов обработки авиационного транспорта с применением сертифицированных средств.</p>
        <h4>Удаление плесени</h4>
        <p>Игнорирование плесени способно стать причиной:</p>
        <ul>
          <li>порчи пищевых продуктов, находящихся в помещении, стены которого заражены плесенью;</li>
          <li>порчи текстиля, мебели, предметов интерьера;</li>
          <li>разрушения отделочных материалов и связанным с этим ухудшением эстетики комнаты;</li>
          <li>появления аллергической реакции у тех, кто проживает в помещении.</li>
        </ul>
        <h4>Сколько стоят услуги дезинфекции?</h4>
        <p>Цена дезинфекции в каждом отдельном случае рассчитывается индивидуально, в зависимости от ряда факторов: площади объекта, объема и сложности требуемых работ, интересующего графика проведения мероприятий, кратности обработки и ее продолжительности, количества объектов, подлежащих обработке и их местонахождения.</p>
        <h4>Методы дезинфекции</h4>
        <p>На сегодняшний день существуют такие методы дезинфекции:</p>
        <ul>
          <li>Механический.</li>
        </ul>
        <p>Он включает в себя проветривание помещений, вентилирование, подметание и мытье полов, удаление пыли, стирку текстиля, вытряхивание матрацев, подушек, ватных одеял.</p>
        <ul>
          <li>Физический (термический).</li>
        </ul>
        <p>Применение этого метода предполагает использование природных факторов:</p>
        <ul>
          <li>солнечных лучей;</li>
          <li>облучение УФ-излучателями для обеззараживания воздуха и поверхностей помещения;</li>
          <li>кипячение, обработка кипятком;</li>
          <li>сжигание мусора;</li>
          <li>пастеризация.</li>
        </ul>
        <p>Физический метод является одновременно наиболее надежным и безопасным для персонала. Если условия позволяют, рекомендуется отдать предпочтение ему.</p>
        <h4>Химический</h4>
        <p>Этот метод основан на применении химических средств, которые используются различными способами.</p>
        <p>К ключевым можно отнести:</p>
        <ul>
          <li>орошение;</li>
          <li>распыление;</li>
          <li>протирание;</li>
          <li>полное погружение.</li>
        </ul>
        <h4>Комбинированный</h4>
        <p>Суть этого метода - в одновременной комбинации нескольких методов. К примеру, камерная обработка постельных принадлежностей производится с применением химического и физического методов.</p>
        <p>Выбор оптимального метода дезинфекции в каждом отдельном случае подбирается индивидуально, в зависимости от эпидемиологического, токсического, экологического, экономического и ряда других факторов, а также от свойств материала, из которого изготовлен объект, который планируется подвергнуть обработке.</p>
      </div>
    </div>
  );
};

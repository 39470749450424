import React from 'react';
import BagSVG from '../../../images/svg/bag.svg';
import { Seo } from '../../Seo';
import * as styles from '../services.module.css';

export const DisinsectionContent = ({ handleOpenModal }) => {
  return (
    <div className={styles.initialSectionOfContent}>
      <Seo postTitle="Дезинсекция - вашего помещения" />
      <div className={styles.wrapOfFixedSection}>
        <div className={styles.headeOfTabContent}>
          <div className={styles.titleAndDescription}>
            <h1 className={styles.contentTitle}>Дезинсекция</h1>
            <p className={styles.contentDescription}>уничтожение различных видов насекомых, являющихся вредителями</p>
          </div>
          <BagSVG className={styles.contentIcon} />
        </div>
        <ul className={styles.contentList}>
          <li className={styles.contentListItem}>Использование химических препаратов III-IV класса опасности.</li>
          <li className={styles.contentListItem}>Бесплатное повторное проведение процедуры, если результат не был достигнут</li>
          <li className={styles.contentListItem}>Круглосуточный график - заявки принимаются 24/7, включая выходные и праздничные дни</li>
        </ul>
        <button onClick={handleOpenModal} className={styles.orderService}>заказать услугу</button>
      </div>

      <div className={styles.bodyContent}>
        <h2>Услуги дезинсекции</h2>
        <p>Под термином «дезинсекция» подразумевается проведение ряда мероприятий, направленных на борьбу и полное уничтожение различных видов насекомых, являющихся вредителями и потенциальными переносчиками опасных инфекционных заболеваний.</p>
        <p>В процессе работы служба дезинсекции домов в Москве использует исключительно зарегистрированные и имеющие разрешение к использованию на территории Российской Федерации препараты III-IV класса опасности. Несмотря на низкий класс опасности, применяемые средства для дезинсекции отличаются высокой эффективностью.</p>
        <p>В том случае если необходимый результат не будет достигнут после первого проведения процедуры, служба дезинсекции «Continental Ecoservice» гарантирует повторный, абсолютно бесплатный для клиента, приезд дезинфектора на объект.</p>
        <h4>Каких вредителей уничтожают?</h4>
        <p>В настоящее время служба дезинсекции в Москве обладает большим опытом в проведении работ по уничтожению различных видов насекомых и обеззараживанию помещений любого предназначения, а также открытых участков.</p>
        <p>Для обработки используются качественные современные препараты, к которым у вредителей еще не выработалась устойчивость, что гарантирует достижение желаемого результата уже после первого проведения процедуры.</p>
        <p>Служба уничтожения клопов предоставляет профессиональные услуги также по борьбе с тараканами, блохами, клещами, мухами, осами, шершнями, молью, комарами, вшами, муравьями, а также любыми другими видами насекомых, доставляющих дискомфорт.</p>
        <h4>На каких объектах может быть проведена дезинсекция?</h4>
        <p>Служба по борьбе c насекомыми предоставляет широкий спектр услуг любого уровня сложности по дезинсекции объектов различного предназначения, типа и площади.</p>
        <p>Фирма специализируется на обработке следующих видов объектов:</p>
        <ul>
          <li>производственные зоны фабрик и заводов, цеха, подсобные и складские помещения;</li>
          <li>зоны, ориентированные на торговлю пищевыми продуктами;</li>
          <li>общежития, хостелы, отели, гостиничные комплексы;</li>
          <li>любые виды общественного транспорта;</li>
          <li>учебные заведения (детские сады, интернаты, школы, ВУЗы);</li>
          <li>заведения общественного питания (столовые, кафе, рестораны);</li>
          <li>административные здания;</li>
          <li>объекты жилищно-коммунального комплекса;</li>
          <li>аптеки, поликлиники, больницы и другие типы медицинских учреждений;</li>
          <li>квартиры, особняки, коттеджи, дачи, открытые участки.</li>
        </ul>
        <p>В арсенале службы «Continental Ecoservice» большой ассортимент эффективных химических средств для безупречного решения поставленных задач.</p>
        <p>Как происходит уничтожение вредителей?</p>
        <p>После обращения заказчика, сотрудник нашей компании задаст ряд уточняющих вопросов с целью выявления основных мест скопления насекомых и их видов, после чего проводится тщательный осмотр помещения или помещений, включая и все труднодоступные мест.</p>
        <ul>
          <li>Подготовка</li>
        </ul>
        <p>Перед началом дезинсекции объекта химическими препаратами, рекомендуется проведение влажной уборки помещения, что обусловлено необходимостью сохранения препарата на всех обработанных поверхностях в ближайшие двое-трое последующих суток. Продукты питания рекомендуется упаковать в герметичные пакеты и убрать в холодильник.</p>
        <ul>
          <li>Дезинсекция</li>
        </ul>
        <p>Второй этап – выбор оптимального варианта химического препарата и оборудования, и непосредственно процесс обработки пораженных помещений. В случае, если цель дезинсекции – истребление колоний муравьев, особенное внимание уделяют санации кухни, ванной комнаты и туалета.</p>
        <ul>
          <li>Инструктаж</li>
        </ul>
        <p>Заключительный этап – предоставление заказчику рекомендаций и инструкций, касающихся поддержания надлежащего санитарного состояния объекта во избежание повторного появления вредителей.</p>
        <h4>Преимущества службы дезинфекции «Continental Ecoservice»</h4>
        <p>Компания предлагает услуги по дезинсекции в городе Москва, ближнем Подмосковье и Московской области физическим и юридическим лицам. Принимаются заявки на проведение дезинсекции объектов любого предназначения и площади, а также открытых участков.</p>
        <p>Сотрудничество с компанией «Continental Ecoservice» имеет ряд неоспоримых преимуществ:</p>
        <ul>
          <li>Круглосуточный график работы. Прием заявок на вызов специалистов, как и проведение дезинсекционных мероприятий, проводится 24/7, включая выходные и праздничные дни.</li>
          <li>Официальный договор. Служба дезинсекции квартир в Москве заключает договор и предоставляет акты выполненных работ, принимаемые Роспотребнадзором и проверяющими из санитарной эпидемиологической станции.</li>
          <li>Истребление любых видов вредоносных насекомых. Служба дезинсекции «Continental Ecoservice» принимает заявки на обработку любых типов объектов и уничтожение комаров, муравьев, блох, вшей, клещей, клопов, тараканов, ос, мух и прочих.</li>
          <li>Использование сертифицированных препаратов. Для проведения обработки жилых и нежилых объектов применяются исключительно сертифицированные в Российской Федерации и странах Европейского союза препараты высокого класса безопасности (не представляющие угрозы теплокровным животным).</li>
          <li>Индивидуальный подбор наиболее эффективного препарата в каждом конкретном случае, и создание барьерной защиты.</li>
          <li>Гарантия безопасности. Услуги по санитарной обработке, предоставляемые нашей компанией в полной мере соответствуют требованиям СанПиН.</li>
          <li>Гарантийные обязательства. Службой «Continental Ecoservice» обеспечивается повторный бесплатный выезд специалиста для проведения обработки помещений, в случае появления насекомых в течение гарантийного срока.</li>
          <li>Полная конфиденциальность. По желанию клиента работы по дезинсекции будут проведены конфиденциально. </li>
        </ul>
        <h4>Процесс уничтожения вредоносных насекомых в квартире и в частном доме</h4>
        <p>При обращении к нам с целью вызова специалиста для проведения дезинсекции в квартире, сотрудник проконсультирует заказчика о ряде подготовительных мероприятий, которые следует провести до приезда мастера. Что конкретно необходимо сделать хозяевам квартиры, сотрудник перечислит в зависимости от типа насекомых, с которыми запланирована борьба.</p>
        <p>Среди всех обращений заказчиков, самыми востребованными являются такие услуги, как: травление клопов, уничтожение муравьев, а также тараканов.</p>
        <h4>Уничтожение клопов</h4>
        <p>Если причина вызова специалиста – клопы (дезинфекция), то в квартире необходимо провести тщательную влажную уборку, освободить все кровати и диваны от постельных принадлежностей (белье прокипятить на максимально возможной температуре), мягкую мебель освободить от всех предметов, которые могут воспрепятствовать обработке. Для получения доступа к плинтусам следует отодвинуть диваны и кровати на 15-20 см от стен, также нужно поступить с бытовой техникой и дополнительно накрыть ее полиэтиленовой пленкой.</p>
        <p>Обработка от клопов происходит следующим образом:</p>
        <ul>
          <li>Специалист приезжает в назначенное вами время, и производит осмотр квартиры с целью выявления мест скопления клопов. Далее мастер определяет наиболее эффективный в данном случае препарат. До обработки помещений, сотрудник компании составляет договор и информирует жильцов о правилах, которых необходимо будет придерживаться после проведения дезинсекции.</li>
          <li>Сам процесс уничтожения клопов представляет собой тщательную обработку спальных мест, диванов и кресел, проемов дверей, подоконников, плинтусов, а также всех углов и щелей.</li>
        </ul>
        <p>Уничтожение тараканов. Обработка от муравьев</p>
        <p>В случае если вызов специалиста по дезинсекции обусловлен необходимостью устранения колонии тараканов, прежде всего, нужно освободить все кухонные шкафчики, ящики и тумбочки от продуктов питания, всю посуду и столовые приборы накрыть пленкой и тщательно вымыть половые покрытия, в ванной следует спрятать все предметы личной гигиены.</p>
        <p>Обработка от тараканов и муравьев предусматривает тщательный осмотр специалистом кухни и санузла для определения очага скопления насекомых. В кухне обработке подлежат абсолютно все поверхности: мебель, полы, подоконники, место под холодильником, углы, щели, стояки труб, воздуховоды и т.д. В ванной комнате мастер проводит обработку раковины, ванны, унитаза, и мест около них, а также стояков труб и воздуховодов. С целью предотвращения вероятности миграции насекомых в другие комнаты, специалист обрабатывает плинтуса и дверные проемы, которые граничат с кухней и санузлом.</p>
        <h4>Работы дезинсекции на участке</h4>
        <p>Обработка участка от комаров, клещей, мух и других насекомых – одно из направлений деятельности нашей службы дезинсекции. Также принимаются заявки на уничтожение вредоносных насекомых на дачных участках, в садах, огородах, земельных участках, прилегающих к особнякам, летних террасах ресторанов, кафе, санаториев и баз отдыха.</p>
        <p>В случае, когда речь идет о дезинсекции на открытых участках, специалисту необходимо принять во внимание особенности местности: рельеф, наличие или отсутствие водоемов, виды произрастающих тут растений, погодные факторы и другие, имеющие значение, моменты.</p>
        <p>Процедуры, направленные на дезинсекцию могут быть проведены, как на отдельных участках, так и на объединенных территориях, что является очень эффективным решением для дачных кооперативов, совместных территорий санаториев, деревень и поселков.</p>
      </div>
    </div>
  );
};

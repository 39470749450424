import React from 'react';
import RatSVG from '../../../images/svg/rat.svg';
import { Seo } from '../../Seo';
import * as styles from '../services.module.css';

export const DeratizationContent = ({ handleOpenModal }) => {
  return (
    <div className={styles.initialSectionOfContent}>
      <Seo postTitle="Дератизация - вашего помещения" />
      <div className={styles.wrapOfFixedSection}>
        <div className={styles.headeOfTabContent}>
          <div className={styles.titleAndDescription}>
            <h1 className={styles.contentTitle}>Дератизация</h1>
            <p className={styles.contentDescription}>истребление мышей, крыс, кротов и прочих видов грызунов</p>
          </div>
          <RatSVG className={styles.contentIcon} />
        </div>
        <ul className={styles.contentList}>
          <li className={styles.contentListItem}>Использование ядовитых веществ и смесей в местах питания, доступа до воды и миграций грызунов</li>
          <li className={styles.contentListItem}>Применение ловушек и капканов</li>
          <li className={styles.contentListItem}>Бесплатное повторное проведение процедуры, если результат не был достигнут</li>
          <li className={styles.contentListItem}>Круглосуточный график - заявки принимаются 24/7, включая выходные и праздничные дни</li>
        </ul>
        <button onClick={handleOpenModal} className={styles.orderService}>заказать услугу</button>
      </div>

      <div className={styles.bodyContent}>
        <h2>Услуги дератизации</h2>
        <p>Под термином «дератизация» подразумевается комплекс санитарно-эпидемиологических мер и мероприятий, направленных на истребление мышей, крыс, кротов и прочих видов грызунов, а также на предотвращение их последующего появления и размножения.</p>
        <p>Во время комплексной очистки объекта, характеризующегося запущенными условиями эксплуатации, могут быть применены все способы и методы санитарно-эпидемиологической обработки: механический и химический:</p>

        <ul>
          <li>Механический способ базируется на использовании разного рода ловушек и капканов для отлова опасных животных.</li>
          <li>Химический метод подразумевает раскладывание ядовитых веществ и смесей в местах питания, доступа до воды и миграций грызунов. Ядовитый препарат может подаваться в виде газа – холодного либо горячего тумана, а также приманок.</li>
        </ul>

        <p>Какому из методов отдать предпочтение, решает специалист на основе информации, полученной после очного осмотра помещения или объекта, изучения текущей ситуации и анализа численности особей. В большинстве случаев для достижения желаемого результата и стойкого эффекта недостаточно применения одного из способов, рекомендуется использовать комплекс мероприятий.</p>

        <h4>Каких животных уничтожают?</h4>

        <p>Служба дератизации «Continental Ecoservice» предлагает полный комплекс профессиональных услуг по дератизации всех видов грызунов:</p>

        <ul>
          <li>крысы серые;</li>
          <li>крысы черные;</li>
          <li>полевые мыши;</li>
          <li>домовые мыши;</li>
          <li>кроты;</li>
          <li>другие.</li>
        </ul>

        <h4>На каких объектах работают?</h4>

        <p>Служба дератизации «Continental Ecoservice» принимает заявки на проведение комплекса санитарно-эпидемиологических мероприятий на объектах следующих видов:</p>

        <ul>
          <li>торговые точки по продаже пищевых продуктов (магазины, ларьки, супермаркеты, рынки и т.д.);</li>
          <li>объекты общественного питания (закусочные, столовые, кафе, рестораны, бары и т.д.);</li>
          <li>общежития, мотели, хостелы, военные казармы, гостиничные комплексы;</li>
          <li>производственные объекты: заводы, фабрики в пищеперерабатывающей промышленности, птицеводстве, животноводстве и т.д.;</li>
          <li>лечебно-профилактические учреждения: детские лагеря, пансионаты, санатории, профилактории;</li>
          <li>лечебные заведения: госпитали, больницы, клиники, туберкулезные диспансеры, родильные дома.</li>
          <li>культурно-образовательные заведения: библиотека, детские сады, школы, интернаты, учебные корпусы институтов и университетов.</li>
          <li>объекты жилищно-коммунального хозяйства: квартиры, частные дома, дачи, коттеджи, офисные здания;</li>
          <li>элеваторы, склады;</li>
          <li>транспорт: суда, ж/д вагоны;</li>
          <li>порты, береговые объекты, открытые участки других типов.</li>
        </ul>

        <h4>Как происходит процесс уничтожения?</h4>

        <p>В настоящее время разработано и активно применяется множество действенных методов борьбы с грызунами. Уничтожение грызунов в доме, квартире, подвале, на чердаке, участке, и на разного рода промышленных объектах производится с помощью:</p>

        <ul>
          <li>пищевых химических ядов, приманивающих грызунов;</li>
          <li>распыляемых ядов;</li>
          <li>ультразвуковых приспособлений;</li>
        </ul>

        <p>Помимо непосредственного истребления, для достижения желаемого результата специалистами по дератизации предпринимается и целый комплекс профилактических мер. Главная цель профилактики – создание для грызунов некомфортных условий для проживания и размножения, и препятствование их проникновению на территорию обслуживаемого объекта.</p>
        <p>Все методы, направленные на полное уничтожение грызунов, делят на такие виды:</p>

        <ul>
          <li>Химический. Его суть – в отравлении грызунов ядами, расставленными в местах их наибольшей локализации.</li>
          <li>Ультразвуковой. Данный способ предполагает использование УЗ волн для отпугивания и дальнейшего истребления грызунов. Из-за невысокой надежности и появления более эффективных и удобных методов, ультразвуковой способ используется все реже.</li>
        </ul>

        <h4>Преимущества компании</h4>

        <p>Служба дератизации «Continental Ecoservice» предлагает профессиональные услуги по уничтожению грызунов в Москве и области физическим и юридическим лицам. Мы принимаем заявки на вызов специалиста для проведения полного санитарно-эпидемиологического комплекса мер по уничтожению грызунов на объектах любого предназначения, а также на открытых участках.</p>
        <p>Сотрудничество со службой «Continental Ecoservice» имеет ряд безусловных преимуществ:</p>

        <ul>
          <li>Возможность подать заявку 24/7, в том числе в праздничные и выходные дни. Проведение дератизации также может быть выполнено в любое время суток и в любой день.</li>
          <li>Официальный договор. Мы заключаем официальный договор с каждым нашим клиентом, а по окончании предоставления услуг выдаем на руки акт выполненных работ.</li>
          <li>Комплексные меры по уничтожению грызунов. Компания «Continental Ecoservice» принимает заявки на проведение дератизации любых видов грызунов, вне зависимости от их численности и локализации.</li>
          <li>Применение сертифицированных препаратов. Принимая заявки на уничтожение грызунов в Москве, мы гарантируем, что нашими специалистами используются исключительно сертифицированные в Российской Федерации и странах Европейского союза препараты, как для обработки жилых, так и нежилых помещений.</li>
          <li>Индивидуальный подход. В каждом конкретном случае, специалист службы «Continental Ecoservice» подбирает оптимальный комплекс мер, который продемонстрирует наилучший результат в данных обстоятельствах.</li>
          <li>Гарантия полной безопасности. Комплексные меры по уничтожению крыс, мышей и других видов опасных грызунов, проводимые нашей службой, полностью соответствуют требованиям, выдвигаемым СанПиН.</li>
          <li>Предоставление гарантийных обязательств. В случае повторного появления грызунов в течение гарантийного срока, служба «Continental Ecoservice» предоставляет повторный (совершенно бесплатный) выезд специалиста на объект.</li>
          <li>Конфиденциальность. Мы всегда прислушиваемся ко всем пожеланиям наших клиентов. Если это необходимо, мы готовы предоставить услуги, направленные на уничтожение крыс в Москве абсолютно конфиденциально. </li>
        </ul>

        <h4>Процесс уничтожения в доме</h4>

        <p>Борьба с крысами в частном доме – один из наиболее частых запросов, которые поступают в службу дератизации «Continental Ecoservice». Крысы и мыши являются опасными возбудителями и переносчиками инфекций, кроме того они становятся источником неприятного запаха в доме, а также виновниками порчи и заражения продуктов питания.</p>
        <p>На сегодняшний день дератизация в частном доме происходит с использованием химических препаратов антикоагулянтов, помещаемых в местах скопления вредителей. Под воздействием ядов и химических препаратов удается полностью ликвидировать мышиные гнезда. В качестве дополнительных мер нередко используют разного рода механические и электронные ловушки, а также ультразвуковые установки.</p>
        <p>Для борьбы с кротами высокую эффективность демонстрирует применение в различных комбинациях капканов и ловушек, дополненных приборами с ультразвуковым воздействием.</p>

        <h4>Работы дератизации на участке</h4>

        <p>Владельцы земельных и садовых участков, столкнувшиеся с появлением вредоносных грызунов, знают не понаслышке, что избавиться от них своими силами практически невозможно, так как они отличаются повышенной осторожностью и сильно развитым инстинктом самосохранения. При этом крысы являются переносчиками очень опасных заболеваний: лихорадки, чумы, бешенства и прочих, из-за чего бороться с ними следует нещадно, и ни в коем случае не затягивать с вызовом специалиста.</p>
        <p>Сама процедура обработки состоит из таких этапов:</p>

        <ul>
          <li>тщательное обследование объекта мастером;</li>
          <li>составление плана: уничтожение мышей, крыс, кротов и т.д., или проведение профилактических мероприятий.</li>
          <li>непосредственно дератизация на участке;</li>
          <li>профилактика, направленная на предотвращение вероятности повторного появления грызунов (заделывание нор, установка специальных ловушек и т.д.);</li>
          <li>утилизация погибших особей.</li>
        </ul>

        <p>Наибольшую эффективность в истреблении опасных грызунов демонстрирует применение химических препаратов в совокупности с использованием механических приспособлений. Популярное ультразвуковое отпугивание, к которому самостоятельно прибегают многие землевладельцы, имеет один весомый недостаток: как только приспособление отключается, грызуны в тот же час возвращаются.</p>
      </div>
    </div>
  );
};

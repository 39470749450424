// extracted by mini-css-extract-plugin
export var services = "services-module--services--3FKyN";
export var wrapOfFixedSection = "services-module--wrapOfFixedSection--JFsUm";
export var navigation = "services-module--navigation--2HPcU";
export var item = "services-module--item--2DV2U";
export var active = "services-module--active--2nQfl";
export var itemTitle = "services-module--itemTitle--1qNNI";
export var itemDescription = "services-module--itemDescription--1AL3i";
export var itemIcon = "services-module--itemIcon--KQaXj";
export var tabContent = "services-module--tabContent--3et7U";
export var initialSectionOfContent = "services-module--initialSectionOfContent--3fkSc";
export var headeOfTabContent = "services-module--headeOfTabContent--3kpkJ";
export var titleAndDescription = "services-module--titleAndDescription--1_DGN";
export var contentIcon = "services-module--contentIcon--24iP1";
export var contentTitle = "services-module--contentTitle--1GlJa";
export var contentDescription = "services-module--contentDescription--1kqZz";
export var contentList = "services-module--contentList--1zRSz";
export var contentListItem = "services-module--contentListItem--3m5Ad";
export var orderService = "services-module--orderService--1h263";
export var bodyContent = "services-module--bodyContent--1vUZI";